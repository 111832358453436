.footer {
    background-color: rgba(26, 26, 46, 0.9);
    padding: 3rem 0 1rem 0;
    margin-top: 2rem;
    backdrop-filter: blur(10px);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    scroll-margin-top: 70px;
    /* Add space for fixed navbar */
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 0 1rem;
}

.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-margin-top: 70px;
    /* Add space for fixed navbar */
}

.footer-section h3 {
    color: #fff;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    position: relative;
}

.footer-section h3::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 2px;
    background: #8A2BE2;
}

.social-links {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.social-media-item {
    height: 3rem;
    width: 3rem;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.social-media-item:hover {
    transform: translateY(-5px);
    border-color: #8A2BE2;
}

.footer-section p {
    color: #fff;
    margin: 0.5rem 0;
    font-size: 0.9rem;
    text-align: center;
}

.footer-bottom {
    text-align: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .footer-content {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .footer-section {
        margin-bottom: 2rem;
    }

    .social-links {
        justify-content: center;
    }
}