.dashboarddd {
    background-size: cover;
    background-position: center;
    margin-top:60px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .rounded-button {
    background-color: #F60B8A;
    color: #fff;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    width: 200px;
    height: 50px;
    margin-top: 40px ;
    margin-bottom: 50px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }
  
  /* Optional: Add hover effect */
  .rounded-button:hover {
    opacity: 0.9;
    color:#690239;
  }
  
  .logos {
    width: 100%;
    max-width: 1000px; /* Adjust the maximum width as needed */
    height: auto;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .logos {
      max-width: 80%; /* Adjust the maximum width for smaller screens */
    }
  
    .rounded-button {
      height: 40px; /* Adjust button height for smaller screens */
    }
  }
  