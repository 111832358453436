.logo_summit{
  justify-content: flex-start;
}

.navbar_collapse_end{
  justify-content: flex-end;
}

.nav_link_font{
  font-size: large;
}

.nav_link_font:hover{
  text-decoration: underline;
  text-decoration-color: #F200DE;
  text-decoration-thickness: 3px;
}

.flex{
  display: flex;
}
.navbar{
  justify-content: space-between;
  align-items: center;
}

.navbar-collapse{
  justify-content: space-between;
  align-items: center;
}

.navbar-items{
  height: 100%;
  align-items: center;
  color: rgb(255,255,255,0.7);
  font-size: 0.9rem;
}

.navbar-items_a:hover{
color: aliceblue;
font-weight: bold;
}

.navbar-items div{
  cursor: pointer;
}

.Navbar_links{
  text-decoration: none;
  color: white;
}

/* Mobile Devices (up to 767px) */
@media only screen and (max-width: 767px) {
  .navbar{
    padding: 2.5rem 2rem;
  }

  .navbar-collapse{
    flex-grow: 0;
  }

  .logo{
    height: 6vh;
  }

  .navbar-collapse{
    text-align: center;
  }

  .navbar-items{
    flex-direction: column;
    font-size: 1rem;
  }

  .navbar-items div{
    margin: 0.5rem 1.125rem;
  }

  .navbar .join-button{
    height: 7vw;
    width: 30vw;
    margin-top: 10px;
  }
}

/* Tablet Devices (768px to 991px) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar{
    padding: 3rem 4rem;
  }

  .navbar-collapse{
    flex-grow: 0;
  }

  .logo{
    height: 7.5vh;
  }

  .navbar-collapse{
    text-align: center;
  }

  .navbar-items{
    flex-direction: column;
    font-size: 1.3rem;
  }

  .navbar-items div{
    margin: 0.75rem 1.125rem;
  }

  .navbar .join-button{
    height: 6vw;
    width: 23vw;
    margin-top: 15px;
    font-size: 1.2rem;
  }
}

/* Small Desktops and Laptops (992px to 1199px) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar{
    padding: 2rem 5rem;
  }

  .navbar-collapse{
    flex-grow: 0;
    width: 55vw;
  }

  .logo{
    height: 8vh;
  }

  .navbar-items div{
    margin: 0rem 1.125rem;
  }

  .navbar .join-button{
    height: 3vw;
    width: 9vw;
    font-size: 0.8rem;
  } 
}

/* Large Desktops (1200px and above) */
@media only screen and (min-width: 1200px) {
  .navbar{
    padding: 2.5rem 7rem;
  }

  .navbar-collapse{
    flex-grow: 0;
    /* width: 55vw; */
  }

  .logo{
    height: 9vh;
  }

  .navbar-items div{
    margin: 0rem 1.125rem;
  }

  .navbar .join-button{
    height: 3vw;
    width: 10vw;
  }
}
