.esummit-registration {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  background-image: url('./form_background.png');
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
  font-family: Plus Jakarta Sans,sans-serif;
}

.esummit-registration .logo {
  height: 80px;
  margin: 20px;
}

.form-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-container .heading {
  font-size: 40px;
  line-height: 60px;
  height: 70px;
  letter-spacing: 2%;
  font-weight: 600;
  text-decoration: underline #F200DE;
  color: white;
}

.registration-form {
  width: 80vw;
  border-radius: 49px;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 7.5vh 20vh;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vh;
}

/* Styling for checkboxes */

.form-container .btn-container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.form-container .btn-container button {
  padding: 10px 40px;
  margin-top: 40px;
  border-radius: 0px 28px 0px 28px;
  background: linear-gradient(to right, #F200DE, #BA00AB, #8C0080);
  border: 2px solid #FF00EA;
  font-size: 25px;
  line-height: 40px;
  color: white;
  cursor: pointer;
}

/* Events Section */

.form-container .events {
  width: 80vw;
  text-align: center;
  margin-bottom: 50px;
}

.form-container .events-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: auto;
  margin-bottom: 50px;
}

.form-container .events-section {
  text-align: center;
}

.form-container .events-section h3{
  color: #FF00EA;
  text-decoration: underline 2px white;
}

.event {
  border-radius: 5px;
  width: 70vw;
  padding: 10px;
  font-size: 4vh;
  margin: 5px;
  color: white;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.31);
  transition: background-color 0.3s, transform 0.3s;
}

.event:hover {
  background-color: #F200DE;
  transition: background-color 0.3s, transform 0.3s;
}



@media screen and (max-width: 992px) {

  .registration-form {
    width: 95vw;
    padding: 10vh 5vh;
    font-size: 2.3vh;
  }
  /* Styling for checkboxes */
  
  .form-container .btn-container {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  
  .form-container .btn-container button {
    width: 50vw;
  }
  
  /* Events Section */
  
  .form-container .events {
    width: 90vw;
    text-align: center;
  }
  
  .form-container .events-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-height: 60vh;
  }
  
  .form-container .events-section {
    text-align: center;
  }

  
  .event {
    width: 80vw;
    padding: 10px;
    font-size: 3vh;
  }
  
}
