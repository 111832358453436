.esummit-navbar {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #63239e3d;
    backdrop-filter: blur(10px);
    position: sticky;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar-right {
    display: flex;
    align-items: center;
    padding-right: 50px;
}

@media (max-width: 768px) {
    .navbar {
        height: 60px;
    }

    .navbar-right {
        padding-right: 15px;
    }
}