.mobile-menu {
    display: none;
}

.mobile-menu-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    transition: color 0.3s ease;
}

.mobile-menu-button:hover {
    color: #8A2BE2;
}

.mobile-menu-content {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background: rgba(26, 26, 46, 0.95);
    backdrop-filter: blur(10px);
    width: 100%;
    padding: 1rem;
    transform: translateY(-100%);
    opacity: 0;
    transition: all 0.3s ease;
}

.mobile-menu-content.open {
    transform: translateY(0);
    opacity: 1;
    display: block;
}

.mobile-menu-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobile-menu-content li {
    margin: 1rem 0;
    text-align: center;
}

.mobile-menu-content a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s ease;
}

.mobile-menu-content a:hover {
    color: #8A2BE2;
}

@media (max-width: 768px) {
    .mobile-menu {
        display: block;
    }
}