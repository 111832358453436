.esummit-contact-section {
  text-align: center;
}

.esummit-contact-title {
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
  text-align: center;
  position: relative;
  text-decoration: underline;
  text-decoration-color: #F200DE;
}

.esummit-contact-container {
  background-color: #910085;
  padding: 20px;
  margin: 0 auto;
  max-width: 80%;
  border-radius: 0px 64px 0px 74px;
}

.esummit-contact-query-header {
  margin-bottom: 30px;
  color: white;
  text-align: center;
}

.esummit-contact-club-coordinator,
.esummit-contact-faculty-coordinator {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  color: white;
  text-align: left;
  padding: 5px;
}

.esummit-contact-club-coordinator h3,
.esummit-contact-faculty-coordinator h3 {
  color: #FF9BF7;
  text-decoration: underline #FF9BF7;
}

svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  height: 1.6rem;
}

.esummit-contact-section p {
  margin: 10px 0;
}

.esummit-contact-copyright {
  color: white;
  margin-top: 30px;
}

.esummit-contact-header {
  margin-top: 130px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 768px) {
  .esummit-contact-container,
  .esummit-contact-club-coordinator,
  .esummit-contact-faculty-coordinator {
    max-width: 100%;
    padding: 10px;
    margin: 0 auto;
    border-radius: 0px 64px 0px 74px;
  }

  .esummit-contact-club-coordinator,
  .esummit-contact-faculty-coordinator {
    display: inline-block;
    vertical-align: top;
    width: 70%;
    text-align: left;
    padding: 5px;
  }

  .esummit-contact-club-coordinator h3,
  .esummit-contact-faculty-coordinator h3 {
    color: #FF9BF7;
    text-decoration: underline #FF9BF7;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
    height: 1.6rem;
  }
}