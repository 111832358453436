.content {
  display: flex;
  justify-content: space-between;
}

.segment {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 2.5px;
    margin-bottom: 10px;
    color: #ffffff;
}

.content_span{
    display: contents;
}

.text {
    text-align: center;
    margin-bottom: 10px;
}

.about_text {
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
    text-align: center;
    position: relative;
    text-decoration: underline;
    text-decoration-color:#F200DE;
}
/* 
h1:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    border-bottom: 2px solid #cb1ceb;
    border-width: 3px;
  
} */



.about_header{
    margin-top: 100px;
    margin-bottom: 60px;
}

.content_container{
    max-width: 80%;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    .responsive{
        display: none;
    }
    .segment{
        text-align: center;
        flex-basis: auto;
    }
}
