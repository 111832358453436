.summit_body_copy{
    background-image: url("https://img.freepik.com/free-photo/futuristic-metaverse-empty-room-product-display-presentation-abstract-technology-scifi-with-neon-light-3d-background_56104-2314.jpg");
background-size: contain;
background-attachment: fixed;
}

@media only screen and (max-width: 600px) {
   .summit_body_copy{
   background-color: #0f022d;
   background-image: none;
   }
}